import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginSuccessComponent } from './login-success/login-success.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { LinkExpiredComponent } from './link-expired/link-expired.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import { TopBarComponent } from './top-bar/top-bar.component';
import { MobileNavigatorComponent } from './mobile-navigator/mobile-navigator.component';
import { BottomContainerComponent } from './bottom-container/bottom-container.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerifyDetailsComponent } from './verify-details/verify-details.component';
//import { SideBarComponent } from './side-bar/side-bar.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { FillPipe, ProductComponent } from './product/product.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatCommonModule } from '@angular/material/core'; // for common functionality
import { MatNativeDateModule } from '@angular/material/core'; // for date functionality
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table'; 
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { VenueComponent } from './venue/venue.component';
import { ThemesComponent } from './themes/themes.component';
import { PolicyComponent } from './policy/policy.component';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductLandingComponent } from './product-landing/product-landing.component';

import { NgImageSliderModule } from 'ng-image-slider';
import { ProductsAllComponent } from './products-all/products-all.component';
import { BookingSlotsComponent } from './booking-slots/booking-slots.component';
import { AdminBookingsComponent } from './admin-bookings/admin-bookings.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { BookedDetailsComponent } from './booked-details/booked-details.component';
import { TermsComponent } from './terms/terms.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { BookSelectedSlotsComponent } from './book-selected-slots/book-selected-slots.component';

import { TenantsGridComponent } from './tenants-grid/tenants-grid.component';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { CheckoutModule } from 'paytm-blink-checkout-angular';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HelpComponent } from './help/help.component';
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { UsersComponent } from './users/users.component';
import { GoogleSigninButtonDirective, GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { PaymentComponent } from './payment/payment.component';
// import { ConfirmModelComponent } from './confirm-model/confirm-model.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginSuccessComponent,
    LoginComponent,
    SignUpSuccessComponent,
    PageNotFoundComponent,
    UserActivationComponent,
    LinkExpiredComponent,
    TopBarComponent,
    MobileNavigatorComponent,
    BottomContainerComponent,
    FooterComponent,
    VerifyDetailsComponent,
    LandingPageComponent,
    FacilityDetailsComponent,
    CompanyProfileComponent,
    ProductComponent,
    VenueComponent,
    ThemesComponent,
    PolicyComponent,
    ExceptionsComponent,
    ProductListComponent,
    ProductLandingComponent,
    ProductsAllComponent,
    FillPipe,
    BookingSlotsComponent,
    AdminBookingsComponent,
    BookedDetailsComponent,
    TermsComponent,
    AboutUsComponent,
    BookSelectedSlotsComponent,
    TenantsGridComponent,
    ShowNotificationComponent,
    PaymentStatusComponent,
    HomePageComponent,
    TransactionsComponent,
    HelpComponent,
    PaymentConfirmationComponent,
    ConfirmationDialogComponent,
    UsersComponent,
    PaymentComponent
    // ConfirmModelComponent
  ],
  imports: [
    BrowserModule,
    CheckoutModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatRadioModule,
    NgbModule,
    NgImageSliderModule,
    MatTableModule,
    CdkTableModule,
    MatCommonModule,
    MatTabsModule,
    MatDialogModule,
    AgGridModule.withComponents([]),
    SocialLoginModule,
    GoogleSigninButtonModule,
    MatPaginatorModule,
    MatSortModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
    MatDatepickerModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(
                  '223031877436-q8cr0riahr2cn98oraondg33ootasgv6.apps.googleusercontent.com'
                )
             }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  entryComponents: [BookedDetailsComponent, BookSelectedSlotsComponent, ShowNotificationComponent, ConfirmationDialogComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { DatePipe, DOCUMENT } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ColDef, GridOptions } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'; // Adjust the path as per your project structure
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import {MatTable} from '@angular/material/table';

declare var $: any;
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  gridOptions: GridOptions;
  columnDefs: ColDef[] = [];
  mobileColumnDefs: ColDef[] = [];
  columnDefsRoleId3: ColDef[] = [];
  mobileColumnDefsRoleId3: ColDef[] = [];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  cancelBookingResponse: any;
  showConfirmationIndex: number | null = null;

  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) {

    this.gridOptions = <GridOptions>{};
  }



  searchText: any;
  rowData = [];
  externalUserContext;
  currentUserContext;
  roleId;
  datePipe: DatePipe = new DatePipe('en-US');
  startDate: any;
  endDate: any;
  tenantId: any;
  selectedStartDateStr: any;
  selectedEndDateStr: any;
  private gridApi;
  private gridColumnApi;

  //booking details
  userName: any;
  bookingId: any;
  userEmail: any;
  userPhone: any;
  bookingDateTime: any;
  bookingStatus: any;
  totalAmount: any;
  paymentType: any;
  paymentStatus: any;
  transactionId: any;
  paytmTransactionStatus: any;
  notes: any;
  bookedBy: any;
  slotsBooked = [];
  bookingInfo: any;
  isMobile: boolean = false;

  displayedColumns = ["bookingId","customer", "date", "booking", "amount", "payment", "phone"];
  data = [];
  resultsLength = 100;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = [];
  isLoading = false;
  pageSize = 20;
  @ViewChild(MatTable) table: MatTable<any>;
  //dataSource: MatTableDataSource<Transaction>;

  ngOnInit() {
     this.checkIfMobile();
     this.updateDisplayedColumns();
    this.columnDefs = [
      { headerName: 'BOOKING ID', field: 'bookingId' },
      { headerName: 'CUSTOMER', field: 'userName' },
      { headerName: 'DATE', field: 'bookingDateTime', sortable: true, comparator: this.dateComparator, sort: 'asc', cellRenderer: this.customDateRenderer.bind(this) },
      { headerName: 'BOOKING', field: 'bookingStatus' },
      { headerName: 'AMOUNT', field: 'totalAmount' },
      { headerName: 'PAYMENT', field: 'paymentStatus' },
      { headerName: 'PHONE', field: 'userPhone' },
      /* { headerName: 'Email', field: 'userEmail'},
        { headerName: 'TR STATUS', field: 'paytmTransactionStatus'},
       { headerName: 'TR TYPE', field: 'paymentType'} */
    ];
    this.columnDefsRoleId3 = [
      { headerName: 'BOOKING ID', field: 'bookingId' },
      { headerName: 'DATE', field: 'bookingDateTime', sortable: true, comparator: this.dateComparator, sort: 'asc', cellRenderer: this.customDateRenderer.bind(this) },
      { headerName: 'BOOKING', field: 'bookingStatus' },
      { headerName: 'AMOUNT', field: 'totalAmount' },
      { headerName: 'PAYMENT', field: 'paymentStatus' },
      { headerName: 'PHONE', field: 'userPhone' },
      /* { headerName: 'Email', field: 'userEmail'},
        { headerName: 'TR STATUS', field: 'paytmTransactionStatus'},
       { headerName: 'TR TYPE', field: 'paymentType'} */
    ];
    this.mobileColumnDefs = [
      { headerName: 'BOOKING ID', field: 'bookingId' },
      { headerName: 'CUSTOMER', field: 'userName' },
      { headerName: 'DATE', field: 'bookingDateTime', sortable: true, comparator: this.dateComparator, sort: 'asc', cellRenderer: this.customDateRenderer.bind(this) },
      { headerName: 'AMOUNT', field: 'totalAmount' },
    ];

    this.mobileColumnDefsRoleId3 = [
      { headerName: 'BOOKING ID', field: 'bookingId' },
      { headerName: 'DATE', field: 'bookingDateTime', sortable: true, comparator: this.dateComparator, sort: 'asc', cellRenderer: this.customDateRenderer.bind(this) },
      { headerName: 'BOOKING', field: 'bookingStatus' },
      { headerName: 'AMOUNT', field: 'totalAmount' },
    ];


    this.externalUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    console.log('Transactions Page Current User', this.currentUserContext);
    console.log('Transactions Page Ext User', this.externalUserContext);


    this.tenantId = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).tenantId;
    console.log("Transactions Tenant Id 2", AppConstants.SESSION_STORAGE_TENANTID);



    if (this.externalUserContext) {
      this.roleId = JSON.parse(this.externalUserContext).roleId;
    } else if (this.currentUserContext) {
      this.roleId = JSON.parse(this.currentUserContext).roleId;
    } else {
      this.roleId = 0; // user not logged in
    }

    if (this.roleId == 1) {
      this.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
    }

    // if (this.roleId < 4 && this.roleId > 0) {
    //   this.getAllTransactions(); // Only for Superadmin and admin  Need to add condition
    // }

    this.updateColumnDefs();
    window.addEventListener('resize', this.checkIfMobile.bind(this));

  }

  updateDisplayedColumns() {
      if (this.isMobile) {
        this.displayedColumns = ['bookingId','customer', 'date', 'payment', 'amount'];
      } else {
        this.displayedColumns = [
          'bookingId',
          'customer',
          'date',
          'amount',
          'payment',
          'phone',
          'booking',
        ];
      }
    }

  ngAfterViewInit() {
    this.getData();
  }

  getData(){
    if(this.roleId == 3) {
      this.getAllTransactions();
    } else {
      this.getPaginatedData();
    }
  }

  getPaginatedData() {

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        return this.getTransaction(
          this.paginator.pageIndex,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction
        ).pipe(catchError(() => observableOf(null)));
      }),
      map((empData) => {
        if (empData == null) return [];
        this.resultsLength = empData[1];
        this.isLoading = false;
        return empData[0];
      })
    ).subscribe((empData) => {
      this.data = empData;
    });
  }

  // Custom date renderer function
  customDateRenderer(params) {

    const formattedDate = this.datePipe.transform(params.value, 'dd MMM yy h:mm');
    //console.log("customDateRenderer : ", formattedDate);
    return formattedDate;
  }
  checkIfMobile() {
      this.isMobile = window.innerWidth < 600; // Bootstrap breakpoint for mobile
    }
  updateColumnDefs() {
    const isMobile = window.innerWidth < 600; // Adjust the breakpoint as needed

    if (isMobile) {
      //console.log("RoleId: ", this.roleId);
      if (this.roleId == 3) {
        this.gridOptions.columnDefs = this.mobileColumnDefsRoleId3;
      }
      else {
        this.gridOptions.columnDefs = this.mobileColumnDefs;
      }
    } else {

      if (this.roleId == 3) {
        this.gridOptions.columnDefs = this.columnDefsRoleId3;
      }
      else {
        this.gridOptions.columnDefs = this.columnDefs;
      }

    }

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.updateColumnDefs();
  }

  dateComparator(date1: string, date2: string): number {
    const date1Value = new Date(date1);
    const date2Value = new Date(date2);

    //console.log ("date 1:", date1Value );
    //console.log ("date 2:", date2Value );
    if (date1Value < date2Value) {
      return 1;
    } else if (date1Value > date2Value) {
      return -1;
    } else {
      return 0;
    }
  }


  onEndDateChange(Date) {
    console.log("End Date", Date);
    this.endDate = Date;
    this.paginator.pageIndex = 0;
    this.getData();
  }
  onStartDateChange(Date) {
    console.log("Start Date", Date);
    this.startDate = Date;
    this.paginator.pageIndex = 0;
    this.getData();
  }

  getAllTransactions() {
    console.log("Transactions : GetAllTransactions ");
    var date = new Date();
    //this.startDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.endDate == this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.endDate = this.startDate;
    console.log("Transactions Tenant Id: ", this.tenantId); console.log("Transactions ROle Id: ", this.roleId);
    this.authService.getAllTransactions(this.tenantId, this.startDate, this.endDate, this.roleId).subscribe(
      (response: any[]) => {
        console.log("Transactions: ", response);

        this.rowData = response;
        console.log(" Transactions Data : ", this.rowData);
      },
      (error) => {

      }
    );
  }


  getTransaction(pageIndex, pageSize, sortColumn, sortDirection) {
    console.log("Transactions : GetAllTransactions ");
    var date = new Date();
    //this.startDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.endDate == this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.endDate = this.startDate;
    console.log("Transactions Tenant Id: ", this.tenantId); console.log("Transactions ROle Id: ", this.roleId);
    return this.authService.getTransactionsInPagination(this.tenantId, this.startDate, this.endDate, this.roleId, pageIndex, pageSize, sortColumn, sortDirection, this.searchText);
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  /* onHeaderClicked(event: any) {
      const isHeaderClicked = event.colDef != null;

      if (isHeaderClicked) {
        // Clicked on a header cell
        console.log('Header clicked:', event.colDef.headerName);
      }
  } */
  onRowClicked(event: any) {

    console.log("row id", event);

    if (event == null) {
      return;
    } else {
      console.log("Transaction");
      let bookingData;
      if(this.roleId == 3) {
        bookingData = event.data;
      } else {
        bookingData = event;
      }
      this.userName = bookingData.userName;

      console.log("bookingData : ", bookingData);
      console.log("User Name: ", this.userName);

      this.bookingInfo = bookingData;
      this.bookingId = bookingData.bookingId;
      this.userEmail = bookingData.userEmail;
      this.userPhone = bookingData.userPhone;
      console.log("bookingDateTime: ", bookingData.bookingDateTime);
      this.bookingDateTime = this.getFormattedDateWithTime(bookingData.bookingDateTime);
      this.bookingStatus = bookingData.bookingStatus;
      this.totalAmount = bookingData.totalAmount;
      this.paymentType = bookingData.paymentType;
      this.paymentStatus = bookingData.paymentStatus;
      this.transactionId = bookingData.transactionId;
      this.paytmTransactionStatus = bookingData.transactionStatus;
      this.notes = bookingData.notes;
      this.bookedBy = bookingData.bookedBy;
      this.slotsBooked = bookingData.slotsBooked;
      console.log("Slots Booked : ", this.slotsBooked);
      $("#myModal").modal("show");
    }

  }
  search() {
    console.log("search string: ", this.searchText);
    if(this.roleId == 3) {
      if (this.gridOptions.api) {
        this.gridOptions.api.setQuickFilter(this.searchText);
      }
    } else {
      this.paginator.pageIndex = 0;
      this.getData();
    }
  }
  showModal(): void {
    $("#myModal").modal('show');
  }

  hideModal(): void {
    // document.getElementById('close-modal').click();
    $("#myModal").modal('hide');
  }
  closeModal(): void {
    // document.getElementById('close-modal').click();
    $("#myModal").modal('close');
  }

  convertTo12HourFormat(time24: string): string {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(':').map(Number);

    // Check if hours are greater than 12
    if (hours > 12) {
      // Convert hours to 12-hour format
      const hours12 = hours - 12;
      return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} PM`;
    } else if (hours === 0) {
      // Midnight (00:00) is 12:00 AM in 12-hour format
      return `12:${minutes.toString().padStart(2, '0')} AM`;
    } else if (hours === 12) {
      // Noon (12:00) is 12:00 PM in 12-hour format
      return `12:${minutes.toString().padStart(2, '0')} PM`;
    } else {
      // Hours are already in 12-hour format
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} AM`;
    }
  }
  getFormattedDate(dateStr) {
    const splitDateStr = dateStr.split('T');
    const dateValue = splitDateStr[0];
    const splitDateValue = dateValue.split('-');
    return splitDateValue[2] + ' ' + this.months[parseInt(splitDateValue[1], 10) - 1] + ' ' + splitDateValue[0];
  }

  getFormattedDateWithTime(dateAndTime) {

   return this.datePipe.transform(dateAndTime, 'dd-MMM hh:mm a');

    /* const splitDateStr = dateStr.split('T');
    const dateValue = splitDateStr[0];
    const splitDateValue = dateValue.split('-');
    return splitDateValue[2] + ' ' + this.months[parseInt(splitDateValue[1], 10) - 1] + ' ' + splitDateValue[0] + ' ' + this.convertTo12HourFormat(splitDateStr[1]);
    */
 }

  cancelBooking(): void {

    console.log("Cancel Booking confirmation");

    this.hideModal();
    // Open a confirmation dialog
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { message: 'This action will cancel all the slots in this booking. Are you sure you want to cancel this booking?' },
      panelClass: 'confirmation-dialog-container'

    });
    // Handle dialog closing
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // User confirmed, cancel the booking
        this.cancelBookingResponse = this.authService.cancelBooking(this.bookingId).subscribe(
          (response: any) => {
            console.log("Booking Cancelled : ", this.bookingId);
            this.getData();
          },
          (error) => {
            console.log('cancelBooking' + JSON.stringify(error));
          }
        );
      } else {
        // User cancelled, do nothing or handle accordingly
      }
    });
  }

  cancelSlot(index): void {
    this.showConfirmationIndex = this.showConfirmationIndex === index ? null : index;
  }

  getDateStrFromTime(timeValue) {
    const date = new Date(timeValue);
    return date.getFullYear() + '-' + this.addZeroIfRequired(date.getMonth() + 1) + '-' +
      this.addZeroIfRequired(date.getDate());
  }

  addZeroIfRequired(value: number) {
    if (value > 9) {
      return value;
    }
    return '0' + value;
  }

  confirmAction(info): void {
    this.showConfirmationIndex = null;
    const slotId = this.bookingInfo.tenantId + '_' + this.bookingInfo.productId + '_' + this.getDateStrFromTime(info.date)
      + '_' + info.startTime + '_' + info.endTime + '_' + this.bookingInfo.venueId;
    this.authService.cancelSlots([slotId], this.bookingInfo.bookingId).subscribe(
      (response: any) => {
        console.log("Booking Cancelled for the Booking ID: ", slotId);
        info.status = "Cancelled";
        if (this.roleId < 4 && this.roleId > 0) {
          //this.getAllTransactions(); // Only for Superadmin and admin  Need to add condition
          this.getData();
        }
      },
      (error) => {
        console.log('Cancellation Failed :' + JSON.stringify(error));
      }
    )
  }

  cancelAction(): void {
    this.showConfirmationIndex = null;
  }

  /* cancelBooking()
    {

      this.cancelBookingResponse = this.authService.cancelBooking(this.bookingId).subscribe(
        (response: any) => {
          console.log("Booking Cancelled : ", this.bookingId);
        },
        (error) => {
          console.log('cancelBooking' + JSON.stringify(error));
        }
      )
    } */
}


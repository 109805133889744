<div class = "main-container">
  <nav class="navbar navbar-light navbar-expand-md navigation-clean" style="height: 70px;">
    <div class="container">
      <div class="navbar-brand"style="width: 200px;height: 40px;">
        <div class="def_image" *ngIf="isLoggedIn()">
          <img src="../assets/img/Logo.svg" alt="Lights" style="width:20%" >
          <p class="col-md-4 navbar-brand">Booking Dexter</p>
        </div>
        <div class="def_image" *ngIf="!isLoggedIn()">
          <a  href="">
            <img src="../assets/img/Logo.svg" alt="Lights" style="width:20%">
          </a>
          <a class="col-md-4 navbar-brand" href="" >Booking Dexter</a>
        </div>
      </div>
 <!--     <div class="container text-center">
        <button *ngIf="!(roleId == 1 || roleId == 2)" class="btn btn-primary mt-3" [routerLink]="['businesses']" routerLink="/businesses" (click)="menu()">Book-Now</button>
      </div>-->
      <button data-toggle="collapse" class="navbar-toggler" data-target="#ham-menu">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon" ></span>
      </button>
      <div class="collapse navbar-collapse dropdown-content" id="ham-menu" style="height: 70px;">
        <ul class="nav navbar-nav ml-auto">
          <li *ngIf = "!(roleId == 1 || roleId == 2)" class="nav-item" [routerLinkActive]="['active']" role="presentation" style="height: 40px;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['businesses']" href="#" routerLink="/businesses" (click)="menu()" style="height: 40px; background-color: #EC8B5D; border-radius: 5px; color:#FFF;">Book-Now</a>
          </li>
          <!--<li  *ngIf="!isLoggedIn()" class="nav-item" [routerLinkActive]="['active']" role="presentation" style="height: 40px;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link" (click)="onClickLogin()" style="height: 40px; color: #14274E; font-size: 16px;">Sign-up</a>
          </li>-->
          <li *ngIf="isLoggedIn() && (roleId == 2 || roleId == 0)" class="nav-item hidden-mobile"  [routerLinkActive]="['active']" role="presentation" style="cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['landing-page']" href="#" routerLink="/landing-page/admin-bookings"  (click)="onClickAdmin()" style="cursor: pointer;">Admin</a>
          </li>
          <li *ngIf="logInStatus && (roleId == 1)" class="nav-item"  [routerLinkActive]="['active']" role="presentation" style="height: 40px; cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['tenants-grid']" href="#" routerLink="/tenants-grid"  (click)="onClickTenants()" style="cursor: pointer;">Tenants</a>
          </li>
          <li *ngIf="logInStatus && (roleId == 1)" class="nav-item"  [routerLinkActive]="['active']" role="presentation" style="height: 40px; cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['users']" href="#" routerLink="/users"  (click)="onClickUsers()" style="cursor: pointer;">Users</a>
          </li>
          <li *ngIf="logInStatus && (roleId == 1)" class="nav-item"  [routerLinkActive]="['active']" role="presentation" style="height: 40px; cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['users']" href="#" routerLink="/users"  (click)="onClickPayment()" style="cursor: pointer;">Payment</a>
          </li>
          <li *ngIf="isLoggedIn() && (roleId == 3)" class="nav-item hidden-mobile"  [routerLinkActive]="['active']" role="presentation" style="height: 40px; cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['transactions']" href="#" routerLink="/landing-page/transactions"  (click)="clickedOnTransactions()" style="cursor: pointer;">My Bookings</a>
          </li>
          <li *ngIf="logInStatus  && (roleId == 1 || roleId == 2)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['admin-bookings']" href="/landing-page/admin-bookings" (click)="clickedOnAdminBookings()" style="cursor: pointer;">
              Bookings
              <!--<img  src="../assets/img/appointments.svg" alt="Lights" style="width:30px">-->
              <!--<span style = "margin-left :20px;">Bookings</span>-->
            </a>
          </li>
          <li *ngIf="logInStatus && (roleId == 1 || roleId == 2)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['transactions']" href="/landing-page/transactions" (click)="clickedOnTransactions()" style="cursor: pointer;">
              Transactions
              <!--<img src="../assets/img/settings.svg" alt="Lights" style="width:30px">
              <span  style = "margin-left :20px;">Company Profile</span>-->
            </a>
          </li>
          <li *ngIf="logInStatus && (roleId == 3)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['transactions']" href="/landing-page/transactions" (click)="clickedOnTransactions()" style="cursor: pointer;">
              My Bookings
              <!--<img src="../assets/img/settings.svg" alt="Lights" style="width:30px">
              <span  style = "margin-left :20px;">Company Profile</span>-->
            </a>
          </li>
          <li *ngIf="logInStatus  && (roleId == 1 || roleId == 2)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['company-profile']" href="/landing-page/company-profile" (click)="clickedOnCompanyProfile()" style="cursor: pointer;">
              Company Profile
              <!--<img src="../assets/img/settings.svg" alt="Lights" style="width:30px">
              <span  style = "margin-left :20px;">Company Profile</span>-->
            </a>
          </li>
          <li *ngIf="logInStatus  && (roleId == 1 || roleId == 2)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['products']" href="landing-page/products" (click)="clickedOnProducts()" style="cursor: pointer;">
              <!--<img src="../assets/img/products-monochrome.svg" alt="Lights" style="width:30px">
              <span  style = "margin-left :20px;">Products</span>-->
              Services
            </a>
          </li>
          <li *ngIf="logInStatus && (roleId == 1 || roleId == 2)" class="nav-item hidden-DesktopOrTablet" role="presentation" data-target="#ham-menu" data-toggle="collapse">
            <a class="nav-link blueText" [routerLink]="['venues']" href="landing-page/venues" (click)="clickedOnVenues()" style="cursor: pointer;">
              <!--<img  src="../assets/img/location-pin.svg" alt="Lights" style="width:30px">
              <span style = "margin-left :20px;">Venues</span>-->
              Venues
            </a>
          </li>
          <li *ngIf="!logInStatus" class="nav-item" role="presentation" style="height: 40px; cursor: pointer;" data-target="#ham-menu" data-toggle="collapse">
            <div class="row" >
              <a class="nav-link blueText" (click)="onClickLogin()" style="padding-bottom: 2px;">Login / SignUp</a>
            </div>
          </li>
          <li *ngIf="!logInStatus" class="hidden-mobile">
            <asl-google-signin-button type="icon"></asl-google-signin-button>
          </li>

          <li *ngIf="!logInStatus" class="hidden-DesktopOrTablet" style="padding-left: 5px; height: 42px;">
            <asl-google-signin-button type="icon" type="standard"></asl-google-signin-button>
          </li>

          <li *ngIf="logInStatus" class="nav-item dropdown" role="presentation" style="height: 40px;">
              <a class="nav-link dropdown-toggle blueText" role="button"  id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getLoginDisplayText()}}</a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item blueText" (click)="clickedLogout()">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container text-center">
    <button *ngIf="!(roleId == 1 || roleId == 2)" class="btn mt-3 d-md-none searchVenueBtn" [routerLink]="['businesses']" routerLink="/businesses" (click)="menu()" style="height: 40px;">Browse for Venues and Services</button>
  </div>
</div>

<!-- TD Start <nav class="p-5 bg-white shadow">
  <div class="container">
    <div class="flex justify-between items-center">
      <span class="cursor-pointer">
        <a href="#">
          <img src="../assets/img/Logo.svg" alt="Lights" style="width: 40px;height: 40px;">
          Booking Dexter
        </a>
      </span>
      <span class="text-3xml cursor-pointer mx-2 md:hidden block">
        <img src="../assets/img/menu-outline.svg" alt="Lights" style="width: 40px;height: 40px;" >
      </span>
      <ul class="md:flex md:items-center z-[-1] md:z-auto md:static absolute bg-white w-full left-0 md:w-auto md:py-0 py-4 md:pl-0 pl7 top-[-400px]">
        <li class="mx-4" style="height: 40px;">
            <a class="nav-link active" href="#" style="height: 60px;">Home</a>
        </li>
        <li class="mx-4" style="height: 40px;">
            <a class="nav-link" href="#" style="height: 60px;">Features</a>
        </li>
        <li class="mx-4" style="height: 40px;">
            <a class="nav-link" href="#" style="height: 60px;">Pricing</a>
        </li>
        <li class="mx-4" style="height: 40px;">
            <a class="nav-link" href="#" style="height: 60px;">Book Now</a>
        </li>
        <li class="mx-4" style="height: 40px;">
            <a class="nav-link" href="#" routerLink="/all-products" style="height: 60px;">Products</a>
        </li>
        <li *ngIf="!isLoggedIn()" class="mx-4" style="height: 40px;">
            <a class="nav-link" (click)="onClickLogin()" style="height: 60px; color: #14274E; font-size: 16px;">Login</a>
        </li>
        <li *ngIf="isLoggedIn()" class="mx-4" style="height: 40px;">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 60px; color: #14274E; font-size: 16px;">{{getLoginDisplayText()}}</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" (click)="clickedLogout()">Logout</a>
              </div>
        </li>
      </ul>
    </div>
  </div>
</nav> TD End-->



<!--
    data-toggle="modal" data-target="#myModal"
    <div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body">
          Modal body..
        </div>
      </div>
    </div>
  </div>
   -->

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../AppConstants';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  selectedValue;
  selected;
  externalUserContext;
  currentUserContext;
  roleId;

  options = [
    { label: 'PhonePe', value: 'PhonePe' },
    { label: 'Billdesk', value: 'Billdesk' }
  ];
  constructor( private authService: AuthService) { 
    console.log("In constructor inside payment component ");
  }

  ngOnInit() {
    this.externalUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    console.log('Landing page ', this.currentUserContext);
    console.log('Landing page ext ', this.externalUserContext);

    if (this.externalUserContext) {
      this.roleId = JSON.parse(this.externalUserContext).roleId;
    } else if (this.currentUserContext) {
      this.roleId = JSON.parse(this.currentUserContext).roleId;
    } else {
      this.roleId = 0; // user not logged in
    }
    console.log("In ngOnInit function inside payment confirmation component" + AppConstants.SESSION_STORAGE_ROLEID);
    if (this.roleId === 1) {
      this.getPaymentType();
    }

  }

  getPaymentType() {
    this.authService.getConfig("payment_type").subscribe(
      (response: any[]) => {
        console.log("Payment Type: ", response);
        this.selected = response;
        this.selectedValue = this.selected.propertyValue;
      },
      (error) => {
        console.log("Failed to get Payment Type: ", error);
      }
    );
  }

  onRadioButtonChange(newValue: string): void {
    console.log('Radio button changed to:', newValue);
    let paymentConfig = {
      propertyName : "payment_type",
      propertyValue : newValue 
    }
    this.authService.updateConfig(paymentConfig).subscribe(
      (response: any[]) => {
        console.log("Payment Type: ", response);
        
      },
      (error) => {
        console.log("Failed to get Payment Type: ", error);
      }
    );
  }
}

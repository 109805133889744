import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';
import { AppConstants } from '../AppConstants';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'billdesk-payment-confirmation',
  templateUrl: './billdesk-payment-confirmation.component.html',
  styleUrls: ['./billdesk-payment-confirmation.component.css']
})
export class BilldeskPaymentConfirmation implements OnInit {
  confirmationId: string;
  accessToken: string;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private jwtService: JwtHelperService) {
    console.log("In constructor inside payment confirmation component before getting confirmationId");
    this.confirmationId = this.route.snapshot.queryParams.orderId;
    console.log("confirmationId", this.confirmationId);
    this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_TRANSACTION_PAYMENT_STATUS + this.confirmationId);
    console.log("In constructor inside payment confirmation component before calling confirmBooking");
  }

  ngOnInit() {
    console.log("In ngOnInit function inside payment confirmation component");
  }
}

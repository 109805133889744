import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../AppConstants';
import { LoginModalService } from '../login-modal.service';
import { UIRoutes } from '../UIRoutes';
import { AuthService } from '../service/auth.service';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import {  GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { HttpErrorResponse } from '@angular/common/http';

import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';

// This lets me use jquery
declare var $: any;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  loginDisplayText;
  externalUserContext;
  currentUserContext;
  roleId;
  logInStatus = false;
  user: SocialUser | undefined;
  GoogleLoginProvider = GoogleLoginProvider;

  private routerSubscription: any;

  constructor(private router: Router, private loginModalService: LoginModalService, private authService: AuthService,private readonly socialAuthService: SocialAuthService) {}

  ngOnInit() {
    this.externalUserContext =  sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    if(this.externalUserContext) {
      this.loginDisplayText = JSON.parse(this.externalUserContext).firstName;
    } else if(this.currentUserContext) {
      console.log(this.currentUserContext);
      this.loginDisplayText = JSON.parse(this.currentUserContext).firstName;
    } else {
      this.loginDisplayText = '';
    }

    this.isLoggedIn();

    this.socialAuthService.authState.subscribe((user) => {
      console.log("User logged in via - Gmail -> " + user)
      this.user = user;

      this.authService.loginWithSocialUser(user).subscribe(
        loginResponse => {
          console.log(loginResponse);
          const firstName = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)).firstName;
          this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_SIGN_UP_SUCCESS);
          $(".modal-backdrop").remove();
          $(".modal-content").remove();
        },
        (errorLoginResponse: HttpErrorResponse) => {
          console.log(errorLoginResponse);
        }
      );

    });

   // Subscribe to router events
      this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.collapseMenu();
          }
      });

  }

  ngOnDestroy() {
      if (this.routerSubscription) {
        this.routerSubscription.unsubscribe(); // Clean up subscription
      }
    }

  getLoginDisplayText() {
    this.externalUserContext =  sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
    this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
    if(this.externalUserContext) {
      this.loginDisplayText = JSON.parse(this.externalUserContext).firstName;
    } else if(this.currentUserContext) {
      this.loginDisplayText = JSON.parse(this.currentUserContext).firstName;
    } else {
      this.loginDisplayText = '';
    }
    return this.loginDisplayText;
  }

  onClickLogin() {
    this.menu();
    this.loginModalService.setLoginModalData(true);
  }

  clickedLogout() {
    sessionStorage.clear();
    this.router.navigateByUrl('').then(() => {
      window.location.reload();
    });
  }
  activateCurrentLinkTab() {
    var element = document.getElementById("nav-link");
    console.log("element: ", element);
    element.classList.add("active");

  }
/*   activateCurrentLinkTab() {
      var element = document.getElementById("ham-menu");
      element.classList.find('li.active').remove("active");
      this.parent('li').add("active");

    } */
  onClickAdmin()
  {
    this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_ADMIN_BOOKINGS_PAGE]);
  }

  onClickTenants()
  {
    if (this.roleId == 1)
    {
      this.router.navigate([UIRoutes.ROUTING_MODULE_TENANTS_GRID]);
    }
  }

  onClickUsers()
  {
     if (this.roleId == 1)
     {
       this.router.navigate([UIRoutes.ROUTING_MODULE_USERS]);
     }
  }

  onClickPayment() {
    if(this.roleId == 1) {
      this.router.navigate([UIRoutes.ROUTING_MODULE_PAYMENT])
    }
  }

  clickedOnAdminBookings() {
      this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_ADMIN_BOOKINGS_PAGE]);
      //sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_ADMIN_BOOKINGS);
  }
  clickedOnTransactions() {
        this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_TRANSACTIONS]);
        //sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_COMPANY_PROFILE);
  }
  clickedOnCompanyProfile() {
      this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_COMPANY_PROFILE]);
      //sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_COMPANY_PROFILE);
  }
  clickedOnProducts() {
    this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_PRODUCTS]);
    //sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_PRODUCTS);
  }

  clickedOnVenues() {
    this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_VENUES]);
    //sessionStorage.setItem(AppConstants.SESSION_STORAGE_CLICKED_TAB, AppConstants.TAB_VENUES);
  }

  isLoggedIn() {

    if(this.externalUserContext) {
        this.roleId = JSON.parse(this.externalUserContext).roleId;
      } else if(this.currentUserContext) {
        this.roleId = JSON.parse(this.currentUserContext).roleId;
      } else {
        this.roleId = 0; // user not logged in
     }

    if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT)) {
      this.logInStatus = true;
    } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT) && JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)).status == 'Inactive') {
      this.logInStatus = false;
    } else if(sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT)) {
      this.logInStatus = true;
    }
    //console.log("TBar: ", this.logInStatus);
    return this.logInStatus;
  }

  menu()
  {
        let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
        if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
              element.click();
        }

        //this.activateCurrentLinkTab();
  }
 collapseMenu() {
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    if (navbarToggler && navbarToggler.getAttribute('aria-expanded') === 'true') {
      navbarToggler.click(); // Collapse the menu
    }
  }


  socialSignIn(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
}

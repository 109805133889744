export class APIRoutes {
    // Auth API
    public static AUTH_TOKEN_URI = '/book-now/v1/auth/login';
    public static SEND_OTP_URI = '/book-now/v1/otp/send';
    public static VALIDATE_OTP_URI = '/book-now/v1/otp/validate';
    public static CREATE_EXTERNAL_USER_URI = '/book-now/v1/user/external';
    public static RESET_PASSWORD_URI = '/book-now/v1/user/reset/password';
    public static USER_ACTIVATION_URI = '/book-now/v1/user/activate';
    public static EMAIL_PASSWORD_RESET_LINK_URI = '/book-now/v1/user/email/password-reset';
    public static FACILITY_TYPE_URI = '/book-now/v1/tenant-categories';
    public static SOCIAL_AUTH_TOKEN_URI = '/book-now/v1/auth/social-login'

    // Tenant
    public static CREATE_TENANT_V2_URI = '/book-now/v2/tenant';
    public static GET_TENANT_DETAIS_BY_TENANTID = '/book-now/v1/tenant/';
    public static UPDATE_TENANT_DETAILS_BY_TENANTID = '/book-now/v1/tenant';
    public static UPDATE_GOOGLE_MAPS_LINK_BY_TENANTID = '/book-now/v1/tenant/google-maps-link/';

    public static CREATE_TENANT_IMAGES = '/book-now/v1/tenant-images';
    public static GET_TENANT_IMAGES_BY_TENANTID = '/book-now/v1/tenant-images/';
    public static UPDATE_TENANT_IMAGES_BY_TENANTID = '/book-now/v1/tenant-images/';

    // Amenities
    public static GET_TENANT_AMENITIES_BY_TENANTID = '/book-now/v1/tenant/amenities';
    public static CREATE_TENANT_AMENITY = '/book-now/v1/tenant/amenities';
    public static DELETE_TENANT_AMENITY = '/book-now/v1/tenant/amenities';

    // Holiday
    public static CREATE_TENANT_HOLIDAY_BY_TENANTID = '/book-now/v1/tenant/holiday';
    public static GET_TENANT_HOLIDAYS_BY_TENANTID = '/book-now/v1/tenant/holidays';
    public static DELETE_TENANT_HOLIDAY_BY_HOLIDAYID = '/book-now/v1/tenant/holidays';

    // Rules
    public static CREATE_TENANT_RULE_BY_TENANTID = '/book-now/v1/tenant/rule';
    public static DELETE_TENANT_RULE_BY_RULEID = '/book-now/v1/tenant/rules';

    public static GET_TENANT_RULES_BY_TENANTID = '/book-now/v1/tenant/rules';

    // Product
    public static CREATE_PRODUCT = '/book-now/v1/tenant/product';
    public static UPDATE_PRODUCT = '/book-now/v1/tenant/product/';
    public static GET_ALL_PRODUCTS_BY_TENANTID = '/book-now/v1/tenant/products/';

    // Session
    public static CREATE_SESSION = '/book-now/v1/tenant/session';
    public static DELETE_SESSION = '/book-now/v1/tenant/session/';

    // Venue
    public static CREATE_VENUE = '/book-now/v1/tenant/venue';
    public static UPDATE_VENUE = '/book-now/v1/tenant/venue/';
    public static GET_ALL_VENUES_BY_TENANTID = '/book-now/v1/tenant/venues/';

    // Time Slot Wise Products
        public static CREATE_TIME_SLOT_WISE_PRODUCTS_BY_PRODUCTID = '/book-now/v1/time-slot-wise-products/product/';
        public static CREATE_TIME_SLOT_WISE_PRODUCTS = '/book-now/v1/time-slot-wise-products';

    // Saudagar
    // Tenant images by ID
    public static TENANT_IMAGES = '/book-now/v1/tenant-images'; // add param tenant id
    public static TENANT_AMENITIES = '/book-now/v1/tenant/amenities'; // add param tenant id

    public static ROUTING_MODULE_PRODUCT_ALL = '/book-now/v1/tenants';
    public static ROUTING_MODULE_PRODUCT_SINGLE = '/book-now/v1/tenant';

    public static SINGLE_BOOKING_REQUEST = '/book-now/v1/single/booking';
    public static ALL_SLOTS_OF_TENANT_SINGLE = '/book-now/v1/single/bookings';
    public static ALL_SLOTS_OF_TENANT_MULTI = '/book-now/v1/multi/session/bookings';
    public static SINGLE_SESSION_BOOKING_SLOTS = '/book-now/v1/single-session/bookings/';
    public static MULTI_SESSION_BOOKING_SLOTS = '/book-now/v1/multi-session/bookings/';

    public static INITIATE_TRANSACTION = '/book-now/v1/initiate-transaction';
    public static INITIATE_PHONEPAY_PAY_API = '/book-now/v1/initiate/phonepe/pay/api';
    public static GET_ALL_TRANSACTIONS = '/book-now/v1/transactions';
    public static GET_ALL_USERS = '/book-now/v1/users';
    public static GET_USER_TRANSACTIONS = '/book-now/v1/user/transactions';
    public static CONFIRM_BOOKING = '/book-now/v1/booking';
    public static GET_BOOKING_BY_ID = '/book-now/v1/booking/';
    public static CANCEL_BOOKING = '/book-now/v1/booking/cancel/';
    public static CANCEL_SLOT = "/book-now/v1/booking-slots/cancel"

    public static GET_CONFIG = "/book-now/v1/config/"
    public static UPDATE_CONFIG = "/book-now/v1/config"


    public static INITIATE_BILLDESK_PAYMENT = "/book-now/v1/initiate/billdesk/pay";
}


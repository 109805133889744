import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookSelectedSlotsComponent } from '../book-selected-slots/book-selected-slots.component';
import { AuthService } from '../service/auth.service';
import { AppConstants } from '../AppConstants';
import { Subscription } from 'rxjs';
import { AdminBookingsService } from '../service/admin-bookings.service';
import { ShowNotificationComponent } from '../show-notification/show-notification.component';
import { DatePipe, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-admin-bookings',
  templateUrl: './admin-bookings.component.html',
  styleUrls: ['./admin-bookings.component.css']
})
export class AdminBookingsComponent implements OnInit {
  selectedDateStr = this.getCurrentDate();
  selectedDate = new Date();
  venues = [];
  products = [];
  selectedProduct: any;
  selectedProductName: string;
  selectedServiceType: string; //
  sessionType:string;
  monthlyMultiBookingOption:string;
  monthlyMultiBookingSelectedDates: number[];
  futureDates: string[] = []; // Array to store generated future dates
  maxDate:any;
  minDate:any;
  toDate: any;
  selectedStartDate: string;
  latest_date1: any;


  venuesSubscription: any;
  productsSubscription: any;
  user: any;
  venuesForSelectedProduct = [];
  reloadDataSubscription: Subscription;
  showFullDay = false;
  constructor(private authService: AuthService, private dialog: MatDialog, private adminBookingSvc: AdminBookingsService) {

  // Initialize latest_date1 with the current date
          this.latest_date1 = new Date().toISOString().split('T')[0];

          // Calculate the maximum allowable date (current date + 1 month)
          const maxDate = new Date();
          maxDate.setMonth(maxDate.getMonth() + 1);
          this.maxDate = maxDate.toISOString().split('T')[0];

  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT));

    if( AppConstants.SESSION_STORAGE_ROLEID == 1)
    {
            console.log("Tenant Id Venues ", AppConstants.SESSION_STORAGE_TENANTID);
            this.user.tenantId = AppConstants.SESSION_STORAGE_TENANTID;
            console.log("Tenant Id ", this.user.tenantId);
            //console.log("user", this.user);
    }

    this.getAllVenuesByTenantId(this.user.tenantId);

    this.reloadDataSubscription = this.adminBookingSvc.reloadData.subscribe((data: any) => {
      if (data.reloadData) {
        if(data.confirmationId) {
          this.dialog.open(ShowNotificationComponent, {
            data: data.confirmationId
          });
        } else if(data.cancelledSlot) {
          var snackbar = document.getElementById("snackbar");
          snackbar.className = "show";
          setTimeout(function() {
              snackbar.className = snackbar.className.replace("show", "");
          }, 3000); // Hide after 3 seconds
        }
      }
    });

  }

  getAllVenuesByTenantId(tenantId) {
    this.venuesSubscription = this.authService.getAllVenuesByTenantId(tenantId).subscribe(
      (response: any) => {
        this.venues = [];
        if (response && response.length > 0) {
          response.forEach((venue) => {
            this.venues.push({
              id: venue.id,
              name: venue.venueName,
              productIds: venue.productIds
            });
          });
          this.getAllProductsByTenantId(this.user.tenantId);
        }
      },
      (error) => {
        console.log('getAllVenuesByTenantId - ' + JSON.stringify(error));
      }
    )
  }

  getAllProductsByTenantId(tenantId) {
    this.productsSubscription = this.authService.getAllProductsByTenantId(tenantId).subscribe(
      (response: any) => {
        this.products = [];
        console.log("All Products: ", response);
        if (response && response.length > 0) {
          response.forEach((product) => {
            this.products.push({
              id: product.id,
              name: product.productName,
              type: product.productType,
              durationPerSlot: product.durationPerSlot,
              sessionType: product.sessionType,
              noOfSessions: product.noOfSessions,
              monthlyMultiBookingOption: product.monthlyMultiBookingOption,
              monthlyMultiBookingSelectedDates: product.monthlyMultiBookingSelectedDates
            });
          });
          this.selectedProduct = this.products[0];
          this.selectedProductName = this.selectedProduct.name;
          this.selectedServiceType = this.selectedProduct.type;
          this.sessionType = this.selectedProduct.sessionType;
          this.monthlyMultiBookingOption = this.selectedProduct.monthlyMultiBookingOption;
          this.monthlyMultiBookingSelectedDates = this.selectedProduct.monthlyMultiBookingSelectedDates;
         console.log("SelectedServiceType",this.selectedServiceType);
         console.log("SessionType",this.sessionType);
         console.log("monthlyMultiBookingOption",this.monthlyMultiBookingOption);
         console.log("monthlyMultiBookingSelectedDates",this.monthlyMultiBookingSelectedDates);
          this.onProductChanged();
        }
      },
      (error) => {
        console.log('getAllProductsByTenantId - ' + JSON.stringify(error));
      }
    )
  }

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month: any = currentDate.getMonth() + 1;
    let date: any = currentDate.getDate();
    if (month < 10) {
      month = '0' + month;
    }
    if (date < 10) {
      date = '0' + date;
    }
    return year + '-' + month + '-' + date;
  }

  onDateChange(selectedDate) {
    console.log(selectedDate);
    const dateSelected = selectedDate.split('-');
    const date = new Date();
    date.setDate(dateSelected[2]);
    date.setMonth(parseInt(dateSelected[1], 10) - 1);
    date.setFullYear(dateSelected[0]);
    this.selectedDate = date;
    console.log("Selected Date",this.selectedDate);
  }

  onMultiSessionServiceStartDateChange(startDate: string) {
      //console.log('Selected session count:', startDate);
      this.selectedStartDate = startDate;
      console.log('selected service StartDate selected dates', this.selectedStartDate);
      this.adminBookingSvc.updateServiceStartDate(this.selectedStartDate, this.selectedServiceType, this.monthlyMultiBookingOption, this.sessionType);
      // Handle the change in session count as needed
   }
   onDateChange_MultiSessionSelectAnyDay(selectedDate) {
       console.log('selected Service StartDate Start Any Day', this.selectedStartDate);
       this.adminBookingSvc.updateServiceStartDate(selectedDate, this.selectedServiceType, this.monthlyMultiBookingOption, this.sessionType);
    }

  clearDate() {
        this.toDate = '';
        console.log("Clear Date");
  }
  convertStringToNumberArray(input: string): number[] {
    if (!input) {
      console.error('Input is undefined or empty');
      return [];
    }

    // Split the string by commas, trim any extra spaces, and convert to numbers
    return input
      .split(',')
      .map(item => item.trim()) // Remove extra spaces around the numbers
      .map(Number) // Convert to number
      .filter(value => !isNaN(value)); // Filter out any invalid numbers
  }

  onProductChanged() {
    console.log('selected product name - ' + this.selectedProductName);
    this.selectedProduct = this.products.find(product => product.name === this.selectedProductName);
    this.selectedProductName = this.selectedProduct.name;
    this.selectedServiceType = this.selectedProduct.type;
    this.sessionType = this.selectedProduct.sessionType;
    this.monthlyMultiBookingOption = this.selectedProduct.monthlyMultiBookingOption;
    this.monthlyMultiBookingSelectedDates = this.convertStringToNumberArray(this.selectedProduct.monthlyMultiBookingSelectedDates);


    console.log("OPC :",this.selectedProduct);

    console.log("SelectedServiceType",this.selectedServiceType);
     console.log("SessionType",this.sessionType);
     console.log("monthlyMultiBookingOption",this.monthlyMultiBookingOption);
     console.log("monthlyMultiBookingSelectedDates",this.monthlyMultiBookingSelectedDates);


    if(this.selectedServiceType  == "Multi Session")
    {
      //this.monthlyMultiBookingOption = this.products[this.selectedProductIndex].monthlyMultiBookingOption

      if( this.monthlyMultiBookingOption === "Selected Dates")
      {
        //this.monthlyMultiBookingSelectedDates = this.parseSelectedDates(this.products[this.selectedProductIndex].monthlyMultiBookingSelectedDates);
        console.log("selected dates",this.monthlyMultiBookingSelectedDates );
        this.generateFutureDates();
      }
      else if (this.monthlyMultiBookingOption === "Start Any Day")
      {
        this.adminBookingSvc.updateServiceStartDate(this.latest_date1, this.selectedServiceType, this.monthlyMultiBookingOption, this.sessionType);
      }
      else
      {
        this.monthlyMultiBookingSelectedDates.length = 0; //clear the array for other product types
        //this.selectedStartDate = null;
      }
      console.log("MM Settings1 : ", this.monthlyMultiBookingOption );
      console.log("MM Settings2 : ", this.monthlyMultiBookingSelectedDates );

    }
    else
    {
      this.selectedStartDate = null;
    }

    this.venuesForSelectedProduct = this.venues.filter((venue) => venue.productIds.indexOf(this.selectedProduct.id) > -1);
  }
  generateFutureDates(): void {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth(); // 0-indexed month
        const monthsToGenerate = 2; // Generate dates for the next 2 months
          this.futureDates.length = 0;
        // Loop through the current and next month
        for (let i = 0; i < monthsToGenerate; i++) {
          const month = currentMonth + i; // Calculate the month
          const year = currentYear + Math.floor(month / 12); // Handle year rollover
          const actualMonth = month % 12; // Adjust month to stay within 0-11 range

          // Loop through each day in X
          if (Array.isArray(this.monthlyMultiBookingSelectedDates)) {
              this.monthlyMultiBookingSelectedDates.forEach(day => {
                // Your logic here
                console.log("Day", day);
                                  if (day > 0 && day <= this.daysInMonth(actualMonth, year)) {
                                    const date1 = new Date(year, actualMonth, day);
                                    console.log("Day", date1);
                                    if (date1 > today) {
                                      this.futureDates.push(date1.toLocaleDateString('en-CA')); // Store as 'YYYY-MM-DD'
                                    }
                                  }
              });
            } else {
              console.error('monthlyMultiBookingSelectedDates is not an array:', this.monthlyMultiBookingSelectedDates);
              this.monthlyMultiBookingSelectedDates = []; // Ensure it's an array to avoid further issues
            }


        }

        if (this.futureDates.length > 0) {
              this.selectedStartDate = this.futureDates[0]; // Default to the first date
              this.adminBookingSvc.updateServiceStartDate(this.selectedStartDate, this.selectedServiceType, this.monthlyMultiBookingOption, this.sessionType);
              console.log("StartDate updated in admin booking service");
        }
        console.log('Generated Future Dates:', this.futureDates); // Log the dates
  }
  daysInMonth(month: number, year: number): number {
      return new Date(year, month + 1, 0).getDate(); // Get the last day of the month
  }

  bookNow() {
    console.log('book now clicked');
    this.dialog.open(BookSelectedSlotsComponent, {

      data: {}
    });
  }

  onTabSelectionChanged(event) {
    console.log('on tab selection changed event - ' + JSON.stringify(event));
  }

  ngOnDestroy() {
    if (this.venuesSubscription) {
      this.venuesSubscription.unsubscribe();
    }
    if (this.productsSubscription) {
      this.productsSubscription.unsubscribe();
    }
    if(this.reloadDataSubscription) {
      this.reloadDataSubscription.unsubscribe();
    }
  }
}

<div>
    <p>Select the payment type : </p>
    <div *ngFor="let option of options">
        <label>
          <input 
            type="radio" 
            name="options" 
            [(ngModel)]="selectedValue" 
            (ngModelChange)="onRadioButtonChange($event)"
            [value]="option.value" />
          {{ option.label }}
        </label>
      </div>
</div>


  
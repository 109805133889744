<div class="content-wrapper">
  <div class="container">
    <div *ngIf="SA">
      <!-- Row for the button -->
      <div class="row mt-3">
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary book-now-btn" (click)="CreateNewTenant()">
            Create New Tenant
          </button>
        </div>
      </div>
      <!-- Row for the grid -->
      <div class="row mt-3">
        <div class="col-12">
          <div class="tenantsGrid">
            <ag-grid-angular
              style="width: 100%; height: 200px;"
              class="ag-theme-alpine"
              [rowData]="rowData"
              [columnDefs]="columnDefs"
              (gridReady)="onGridReady($event)"
              (rowClicked)="onRowClicked($event)">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants';
import { AuthService } from '../service/auth.service';
import { UIRoutes } from '../UIRoutes';

@Component({
  selector: 'app-tenants-grid',
  templateUrl: './tenants-grid.component.html',
  styleUrls: ['./tenants-grid.component.css']
})
export class TenantsGridComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) { }

  SA = false;
  rowData = [];
  rowData2 = [];
  externalUserContext;
  currentUserContext;
  roleId;

  columnDefs = [
       { headerName: 'TENANT NAME', field: 'tenantName', width: 250 },  // Names can be long, so more space is needed
        { headerName: 'TENANT ID', field: 'id', width: 120 },            // Fixed-length identifier, short
        { headerName: 'ID', field: 'accountId', width: 120 },            // Another identifier, similar to Tenant ID
        { headerName: 'PHONE', field: 'phoneNumber', width: 180 },       // Needs space for international formats
        { headerName: 'CATEGORY', field: 'tenantCategoryId', width: 150 }, // Typically a small category name or ID
        { headerName: 'STATUS', field: 'status', width: 120 }            // Usually short values like "Active", "Inactive"
  ];


  ngOnInit() {

      this.externalUserContext =  sessionStorage.getItem(AppConstants.SESSION_STORAGE_EXTERNAL_USER_CONTEXT);
      this.currentUserContext = sessionStorage.getItem(AppConstants.SESSION_STORAGE_CURRENT_USER_CONTEXT);
      console.log('Landing page ', this.currentUserContext);
      console.log('Landing page ext ',this.externalUserContext);

      if(this.externalUserContext) {
        this.roleId = JSON.parse(this.externalUserContext).roleId;
      } else if(this.currentUserContext) {
        this.roleId = JSON.parse(this.currentUserContext).roleId;
      } else {
        this.roleId = 0; // user not logged in
      }

      if(this.roleId == 1)
      {
        this.SA = true;
        this.getAllTenants(); // Only for Superadmin Need to add condition
      }
      else
      {
        this.SA = false;

      }
  }
  getAllTenants() {
        this.authService.getAlltenants().subscribe(
          (response: any[]) => {
            console.log("Tenants: ", response);

            this.rowData = response;
          },
          (error) => {

          }
        );
  }

  onRowClicked(event: any) {


          console.log("RowData", this.rowData);
          console.log("RowData", event.data.id);
          AppConstants.SESSION_STORAGE_TENANTID = event.data.id;

          //sessionStorage.setItem(AppConstants.SESSION_STORAGE_TENANTID, String(event.data.accountId));
          console.log("Tenant Id CP", AppConstants.SESSION_STORAGE_TENANTID);
          this.router.navigate([UIRoutes.ROUTING_MODULE_LANDING_PAGE + '/' + UIRoutes.ROUTING_MODULE_COMPANY_PROFILE]);

  }

  onGridReady(params) {
    /* this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit(); */
  }

  CreateNewTenant()
  {
    this.router.navigateByUrl(UIRoutes.ROUTING_MODULE_FACILITY_DETAILS);

  }

}

import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIRoutes } from '../APIRoutes';

@Injectable({
    providedIn: 'root'
})

export class AdminBookingsService {
    private selectedSlotData = [];
    reloadData = new Subject();
    selectedSlotRemoved = new Subject();
    bookingData = [];
    serviceStartDate :any;
    selectedServiceType: string; //
    sessionType:string;
    monthlyMultiBookingOption:string;

    constructor(private httpClient: HttpClient) {
        this.selectedSlotData = JSON.parse(sessionStorage.getItem('selectedSlots'));
        if (!this.selectedSlotData) {
            this.selectedSlotData = [];
        }
    }

    addToSelectedSlots(slotClicked) {

        let startDate: string;
        //console.log("Add Slot date ", slotClicked.date, this.selectedServiceType, this.monthlyMultiBookingOption,this.sessionType);
        if(this.selectedServiceType  == "Multi Session")
        {
           if(this.monthlyMultiBookingOption === "Start Any Day")
           {
              startDate= this.extractDate(slotClicked.date);
           }
           else if (this.monthlyMultiBookingOption === "Selected Dates"){
              startDate= this.getServiceStartDate();
           }
        }
        //console.log("addToSelectedSlots", startDate);
        const slotClicked1 = {
              ...slotClicked, // Copy properties from element[dataKey]
              startDate  // Add startDate dynamically (replace with your actual startDate logic)
        };

        this.selectedSlotData.push(slotClicked1);
        this.updateSelectedSlots();
        console.log("abs selectedSlotDate", this.selectedSlotData);

    }

    updateServiceStartDate(multiSessionStartDate, selectedServiceType, monthlyMultiBookingOption, sessionType){
      this.serviceStartDate = multiSessionStartDate;
      this.selectedServiceType = selectedServiceType;
      this.sessionType = sessionType;
      this.monthlyMultiBookingOption = monthlyMultiBookingOption;
      console.log("Service start date update", this.serviceStartDate);
    }

    extractDate(dateString: string): string {
      if (dateString.startsWith('date_')) {
        return dateString.replace('date_', '');
      }
      console.log("extractDate", dateString);
      return dateString; // If it doesn't start with 'date_', return the original string
    }

    getServiceStartDate(){
      return this.serviceStartDate;
    }

    updateSelectedSlots() {
        const selectedSlots = JSON.stringify(this.selectedSlotData);
        console.log('stringified selectedSlotData - ' + selectedSlots);
        sessionStorage.setItem('selectedSlots', selectedSlots);
    }

    removeFromSelectedSlots(slotData) {
        const selecteSlotDataIds = this.selectedSlotData.map(selectedSlot => selectedSlot.uniqueId);
        const slotIndex = selecteSlotDataIds.indexOf(slotData.uniqueId);
        if (slotIndex > -1) {
            this.selectedSlotData.splice(slotIndex, 1);
            this.updateSelectedSlots();
            if (slotData.canRemoveSlot) {
                this.selectedSlotRemoved.next(slotData);
            }
        }
    }

    getSelectedSlotData() {
        return this.selectedSlotData;
    }

    clearSelectedSlots() {
        this.selectedSlotData = [];
        sessionStorage.removeItem('selectedSlots');
    }

    bookNow(userDetails) {
        const url = APIRoutes.CONFIRM_BOOKING;
        const payload = this.getBookingPayload(userDetails);
        //console.log("userDetails: ", userDetails);
        return this.httpClient.post(url, payload);
    }

    getBookingPayload(userDetails) {
        const payloadData = [];

        //console.log("payload", );
        this.selectedSlotData.forEach((slotData) => {
            payloadData.push({
                uniqueId: slotData.uniqueId,
                amount: slotData.price,
                category: "Placeholder",
                userId: 2,
                advanceProductId: slotData.productId,
                paymentType: "Cash",
                notes: userDetails.notes,
                name: userDetails.name,
                phoneNumber: userDetails.phoneNumber,
                email: userDetails.email,
                startDate:slotData.startDate
            });
        });

        return payloadData;
    }

    getSingleSessionBookingSlots(params: any) {
        console.log('single session booking slots request params - ' + JSON.stringify(params));

        const url = APIRoutes.SINGLE_SESSION_BOOKING_SLOTS + params.tenantId;
        let queryParams = new HttpParams();
        queryParams = queryParams.append('productId', params.productId);
        queryParams = queryParams.append('startDate', params.startDate);
        queryParams = queryParams.append('endDate', params.endDate);

        return this.httpClient.get(url, { params: queryParams});

    }

    getMultiSessionBookingSlots(params) {
        console.log('multi session booking slots request params - ' + JSON.stringify(params));

        const url = APIRoutes.MULTI_SESSION_BOOKING_SLOTS + params.tenantId;
        let queryParams = new HttpParams();
        queryParams = queryParams.append('productId', params.productId);
        queryParams = queryParams.append('startDate', params.startDate);
        queryParams = queryParams.append('endDate', params.endDate);

        return this.httpClient.get(url, { params: queryParams});
    }
}



//Dummy Data.
  // return of([
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-18_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-19_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-20_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-21_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-22_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 1',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 11,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_08:00_09:00_4',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 12,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_09:00_10:00_4',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 13,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_10:00_11:00_4',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 14,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_11:00_12:00_4',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 15,
            //                 'venueId': 4,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_12:00_13:00_4',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:35:23.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     },
            //     {
            //         'venueName': 'Room 2',
            //         'singleBookingAvailableSlots': [
            //             {
            //                 'id': 16,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_08:00_09:00_5',
            //                 'startTime': '08:00',
            //                 'endTime': '09:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 17,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_09:00_10:00_5',
            //                 'startTime': '09:00',
            //                 'endTime': '10:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 18,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_10:00_11:00_5',
            //                 'startTime': '10:00',
            //                 'endTime': '11:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 19,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_11:00_12:00_5',
            //                 'startTime': '11:00',
            //                 'endTime': '12:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             },
            //             {
            //                 'id': 20,
            //                 'venueId': 5,
            //                 'tenantId': 2,
            //                 'uniqueId': '2_3_2022-09-23_12:00_13:00_5',
            //                 'startTime': '12:00',
            //                 'endTime': '13:00',
            //                 'price': 50,
            //                 'productId': 3,
            //                 'sessionId': 2,
            //                 'createdAt': '2022-09-17T07:36:01.000+0000',
            //                 'updatedAt': '2022-09-17T07:35:40.000+0000'
            //             }
            //         ],
            //         'singleBookingBookedSlots': []
            //     }
            // ]);
        // }

<div class="admin-bookings container">
  <div id="topContainer" class="row" style="display: flex; align-items: flex-start; flex-wrap: wrap; position: relative;">
    <!-- Service Selection -->
    <div class="col-md-3 col-sm-12 form-group" style="margin-top:10px;">
      <label class="fieldName" style="font-size:12px; display: block;">SERVICE</label>
      <div class="input-group">
        <select [(ngModel)]="selectedProductName" class="form-control" (change)="onProductChanged()">
          <option *ngFor="let product of products" [value]="product.name">
            {{product.name}}
          </option>
        </select>
      </div>
    </div>

    <!-- Date Selection -->
    <div class="col-md-3 col-sm-12 form-group" style="margin-top:10px;" *ngIf="selectedServiceType === 'Single Session' || monthlyMultiBookingOption === 'Start Any Day'">
      <label class="fieldName" style="font-size:12px; display: block;">DATE</label>
      <div class="input-group">
        <input type="date" [(ngModel)]="selectedDateStr" class="form-control" placeholder="Select Date"
               value="{{selectedDateStr}}" name="selectedDate" (change)="onDateChange($event.target.value)">
      </div>
    </div>

    <!-- Start Date Selection -->
    <div *ngIf="selectedServiceType === 'Multi Session'" class="col-md-3 col-sm-12 form-group" style="margin-top:10px;">
      <div *ngIf="sessionType === 'Monthly' && monthlyMultiBookingOption === 'Selected Dates'">
        <label class="fieldName" style="font-size:12px; display: block;">SELECT THE SERVICE START DATE</label>

          <div *ngIf="monthlyMultiBookingSelectedDates.length > 0;">
            <form>
              <div *ngFor="let date of futureDates; let i = index" style="margin-bottom: 10px;">
                <label style="display: flex; align-items: center;">
                  <input
                    type="radio"
                    name="startDate"
                    [value]="date"
                    [(ngModel)]="selectedStartDate"
                    (change)="onMultiSessionServiceStartDateChange(selectedStartDate)"
                    [checked]="i === 0"
                    style="margin-left: 20px;">
                  <span style="margin-left: 10px; font-size: 12px; font-weight: bold;">{{ date }}</span>
                </label>
              </div>
            </form>
            <p class="blueFieldName">Selected Date: {{ selectedStartDate }}</p>
          </div>

        <!--<div *ngIf="monthlyMultiBookingOption === 'Start Any Day'">
          <div class="input-group">
            <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                   class="form-control"
                   placeholder="yyyy-mm-dd"
                   name="toDate"
                   [min]="minDate"
                   [max]="maxDate"
                   (change)="onDateChange($event.target.value)"
                   (focus)="clearDate()">
          </div>
        </div>-->
      </div>
      <div *ngIf="sessionType === 'Sessions'">
        <label class="fieldName" style="font-size:12px; display: block;">SELECT THE SERVICE START DATE</label>
        <div class="input-group">
          <input type="date" [ngModel]="latest_date1 | date:'yyyy-MM-dd'"
                 class="form-control"
                 placeholder="yyyy-mm-dd"
                 name="toDate"
                 [min]="minDate"
                 [max]="maxDate"
                 (change)="onDateChange_MultiSessionSelectAnyDay($event.target.value)"
                 (focus)="clearDate()">
        </div>
      </div>
    </div>

    <!-- Show Full Day Toggle and Book Now Button -->
    <div class="col-md-3 col-sm-12 form-group right-aligned-buttons">
      <mat-slide-toggle
        class="show-full-day-toggle"
        [(ngModel)]="showFullDay"
        [ngModelOptions]="{standalone: true}"
        color="primary">
        <label class="fieldName" style="font-size:13px;padding-top:10px;">Show Full Day</label>
      </mat-slide-toggle>
      <button class="btn btn-primary book-now-btn" style="margin-top:20px;"   (click)="bookNow()">Book Now</button>
    </div>
  </div>

  <!-- Booking Slots -->
  <div id="booking-slots" *ngIf="selectedDate && selectedProduct && venuesForSelectedProduct.length > 0">
    <mat-tab-group class="slotGrid" preserveContent>
      <mat-tab *ngFor="let venue of venuesForSelectedProduct" [label]="venue.name">
        <app-booking-slots [showFullDay]="showFullDay" [selectedDate]="selectedDate" [venueName]="venue.name" [selectedProduct]="selectedProduct"></app-booking-slots>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div id="snackbar">Slot cancelled successfully.</div>
